import React, {useEffect, useMemo, useState} from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import iCalendarPlugin from '@fullcalendar/icalendar';
import timeGridPlugin from '@fullcalendar/timegrid';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';

import {Button, Modal} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';

function App() {
    const [showModal, setShowModal] = useState(false);
    const [eventInfo, setEventInfo] = useState(null);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [selectGroup, setSelectGroup] = useState('default');
    const [calendarUrl, setCalendarUrl] = useState(process.env.REACT_APP_API_URL + '/calendar' + "/default");
    const [lastRefresh, setLastRefresh] = useState(0);

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        fetch(process.env.REACT_APP_API_URL + "/lastRefresh")
            .then(response => response.text())
            .then(data => {
                setLastRefresh(data);
            });
    }, []);

    const renderEventContent = (eventInfo) => {
        const {title, extendedProps} = eventInfo.event;

        if (eventInfo.view.type === 'dayGridMonth') {
            return (
                <div className="fc-event-inline">
                    <div className="fc-daygrid-event-dot"></div>
                    <div className="fc-event-time">
                        {eventInfo.timeText}
                    </div>
                    <div className="fc-event-title">{title}</div>
                </div>
            );
        }

        let descSplit = extendedProps.description.split("\n");

        return (
            <div className="custom-event">
                <b>{title}</b>
                <br/>
                {eventInfo.timeText}
                <br/>
                {extendedProps.location && <i>Lieu: {extendedProps.location}</i>}
                <br/>
                {extendedProps.organizer && <i>Enseignants: {extendedProps.organizer}</i>}
                <br/>
                {descSplit.map((line, index) => (
                    <p key={index}>{line}</p>
                ))}
            </div>
        );
    };

    const handleEventClick = (clickInfo) => {
        setEventInfo(clickInfo.event);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const memorizedCalendar = useMemo(() => (
        <FullCalendar
            plugins={[bootstrap5Plugin, dayGridPlugin, timeGridPlugin, iCalendarPlugin]}
            initialView={windowWidth < 768 ? "timeGridDay" : "timeGridWeek"}
            themeSystem='bootstrap5'
            nowIndicator={true}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: windowWidth < 768 ? 'timeGridDay' : 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            events={{
                url: calendarUrl,
                format: 'ics',
                success: function (data) {
                    console.log(data);
                    data.forEach(function (event) {
                        event.backgroundColor = event.url || '#3788d8';
                        event.borderColor = event.url || '#3788d8';
                    });
                },
            }}
            initialDate={new Date()}
            locale="fr"
            eventContent={renderEventContent}
            timeZone="Europe/Paris"
            slotMinTime="08:00:00"
            slotMaxTime="21:00:00"
            slotDuration="0:30:00"
            allDaySlot={false}
            eventClick={handleEventClick}
            height={"auto"}
        />
    ), [windowWidth, calendarUrl]);

    const handleChange = (event) => {
        setSelectGroup(event.target.value);
        setCalendarUrl(process.env.REACT_APP_API_URL + '/calendar' + "/" + event.target.value);
    };

    const options = [
        'default', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10',
        '11', '12', '13', '14', '15', '16', '17', '18', '19', '20',
        '21', '22', '23', '24'
    ];

    return (
        <div className="App">
            <h1>SorbonneEDT - SquidDevelopment</h1>
            Dernier fetch: {lastRefresh}
            <br/>
            <label htmlFor="group-select">Select a group: </label>
            <select id="group-select" value={selectGroup} onChange={handleChange}>
                {options.map((option) => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </select>
            <p>Selected Group: {selectGroup}</p>

            {memorizedCalendar}

            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{eventInfo ? eventInfo.title : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Horaire: {eventInfo ? eventInfo.start.toLocaleString() : ''} -> {eventInfo ? eventInfo.end?.toLocaleString() : 'Non défini'}</p>
                    {eventInfo && eventInfo.extendedProps.location && (
                        <p>Lieu : {eventInfo.extendedProps.location}</p>
                    )}
                    {eventInfo && eventInfo.extendedProps.description && (
                        eventInfo.extendedProps.description.split("\n").map((line, index) => (
                            <p key={index}>{line}</p>
                        ))
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Fermer
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default App;
